import React from 'react';

const LoadingPage = () => {
  return (
    <div className="spinner">
      <div className="spinner-body">Loading ...</div>
    </div>
  );
};

export default LoadingPage;
